import { useEffect, useState, useCallback } from "react";
import {
  AccountInfo,
  InteractionStatus,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { loginRequest } from "./config";

export function useFetchToken(
  instance: IPublicClientApplication,
  inProgress: InteractionStatus
) {
  const [loading, setLoading] = useState<boolean>(true);

  const fetchToken = useCallback(
    async (account: AccountInfo) => {
      try {
        const request = {
          scopes: loginRequest.scopes,
          account: account,
        };
        const result = await instance.acquireTokenSilent(request);
        localStorage.setItem("accessToken", result.accessToken);
        setLoading(false);
      } catch (error) {
        console.log("Error during token acquisition:", error);
        setLoading(false);
      }
    },
    [instance]
  );

  useEffect(() => {
    if (instance && inProgress === InteractionStatus.None) {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        fetchToken(accounts[0]);
      }
    }
  }, [instance, inProgress, fetchToken]);
  return { loading };
}
