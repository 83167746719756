import { msalInstance } from "./msalInstance";
import { loginRequest } from "./config";
import jwt_decode from "jwt-decode";

const acquireAccessToken = async (): Promise<string> => {
  const accounts = msalInstance.getAllAccounts();

  if (accounts.length === 0) {
    throw new Error("No accounts detected");
  }
  const request = {
    scopes: loginRequest.scopes,
    account: accounts[0],
  };

  const authResult = await msalInstance.acquireTokenSilent(request);
  return authResult.accessToken;
};

export const validateTokenExpiration = async (token: string | null) => {
  if (token) {
    const tokenExp = jwt_decode<AzureDetails>(token).exp;
    const currentTime = Math.floor(Date.now() / 1000);
    if (tokenExp < currentTime) {
      // refresh token
      const newToken = await acquireAccessToken();
      return newToken;
    }
    // token valid
    return token;
  }
  return null;
};
