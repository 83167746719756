import {
  ApolloClient,
  InMemoryCache,
  useMutation,
  DefaultOptions,
  gql,
  HttpLink,
} from "@apollo/client";
import { validateTokenExpiration } from "../auth/refreshToken";
import { setContext } from "@apollo/client/link/context";
import Environment from "../config/environment";

const { apiUrl } = new Environment();

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const link = new HttpLink({
  uri: `${apiUrl}/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await validateTokenExpiration(
    localStorage.getItem("accessToken")
  );
  localStorage.setItem("accessToken", token || "");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
  defaultOptions,
});

export const MutationByString: any = (mutationString: string) => {
  const query = gql`
    ${mutationString}
  `;
  return useMutation(query);
};

export function QueryByStringWithClient<T = any>(
  queryString: string,
  variables?: any
) {
  const query = gql`
    ${queryString}
  `;
  return client.query<T>({ query, variables });
}
